// https://ufcfightpass.smalyu.ru/api/v1/ - test server
// https://ufcfightpass.ru/api/v1/ - production server
const _apiURL = 'https://ufcfightpass.ru/api/v1/';
const _switcher = false;
// pk_7ac63433084f86b2bafcb5880e1a6 - test public id
// pk_28c66dbb88a749098f33d884e2723 - production public id
const _publicId = 'pk_28c66dbb88a749098f33d884e2723';

export { _switcher, _publicId };
export default _apiURL;

