import { lazy, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import Spinner from '../components/spinner/Spinner';
// import HeaderNotification from '../components/headerNotification/HeaderNotification';
import { styles } from '../core/constants';
import routes from '../routes';

import './app.scss';
import { useDispatch } from 'react-redux';
// import { store } from '../core/store/store';
import { fetchLandingMedia } from '../core/slices/landginMediaSlice';

// динамическая подгрузка только после статической
const MainLanding = lazy(() => import('../pages/MainLanding'));
const B2B = lazy(() => import('../pages/B2B'));
const PersonalAccount = lazy(() => import('../pages/PersonalAccount'));
const YearResults = lazy(() => import('../pages/YearResults'));
const PrivacyPolicy = lazy(() => import('../pages/juridicalPages/PrivacyPolicy'));
const UserAgreement = lazy(() => import('../pages/juridicalPages/UserAgreement'));
const ThanksForPayment = lazy(() => import('../pages/ThanksForPayment'));
const AfishaIntegrated = lazy(() => import('../pages/AfishaIntegrated'));

const Page404 = lazy(() => import('../pages/Page404'));

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLandingMedia());
  }, [dispatch]);

  return (
    <Suspense
      fallback={
        <Spinner
          styles={{
            typeOfSpinner: styles.redSpinnerClass,
            wrapperStyles: { marginTop: '200px' },
          }}
        />
      }
    >
      <Helmet>
        <meta http-equiv='X-UA-Compatible' content='IE=edge' />

        <meta
          name='description'
          content='Прямые трансляции UFC, доступ к полному архиву боев UFC и турнирам по дисциплинам единоборств по всему миру, а также эксклюзивным шоу'
        />
        <meta name='yandex-verification' content='eadd78ca9d04cdae' />
        <link
          href='https://assets-global.website-files.com/5cf5a026c170934c311c121c/5d925f57042e0b00590488bc_UFC_app_icon_favicon.png'
          rel='shortcut icon'
          type='image/x-icon'
        />
        <link rel='canonical' href='https://ufcfightpass.ru' />
        <title>Смотрите прямые трансляции UFC на UFC Fight Pass</title>
      </Helmet>
      <Router>
        <div className='app'>
          {/* <HeaderNotification /> */}
          <main>
            <Routes>
              <Route path={routes.main} element={<MainLanding />} />
              <Route path={routes.b2b} element={<B2B />} />
              <Route path={routes.account} element={<PersonalAccount />} />
              {/* <Route path={routes.yearResults} element={<YearResults />} /> */}
              <Route path={routes.privacy} element={<PrivacyPolicy />} />
              <Route path={routes.agreement} element={<UserAgreement />} />
              <Route path={routes.thanksForPayment} element={<ThanksForPayment />} />
              <Route path={routes.tickets} element={<AfishaIntegrated/>}/>
              <Route path='*' element={<Page404 />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
